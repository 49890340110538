import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { useTranslation } from 'react-i18next';

import './LoginPopup.css';

const LoginPopup = ({ onLogin, onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const { t } = useTranslation();

  const auth = getAuth();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      onLogin();
      onClose(); 
    } catch (error) {
      setError(t(error.code));
    }
  }

  const handleCloseClick = () => {
    onClose();
  }

  return (
    <div className={`popup-login`}>
      <div className="popup-content">
        <label htmlFor="login-input">{t('email')}</label>
        <input id="login-input" type="text" placeholder={t('email')} value={email} onChange={handleEmailChange} />
        <label htmlFor="password-input">{t('password')}</label>
        <input id="password-input" type="password" placeholder={t('password')} value={password} onChange={handlePasswordChange} />
        {error && <div className="error-message">{error}</div>}
        <div className="buttons-login-popup">
          <button className="login-button" onClick={handleLogin}>{t('login')}</button>
          <button className="close-button" onClick={handleCloseClick}>
            <CloseIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPopup;
