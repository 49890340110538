import React from 'react';
import brandLogo from '../assets/brandLogo.png';
import unityLogo from '../assets/unity.png';
import { useTranslation } from 'react-i18next';

import './Footer.css';

const Footer = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <div className="footer-bottom">
          <img src={brandLogo} alt="logo" className="footer-logo" />
          <img src={unityLogo} alt="logo" className="footer-unity-logo" />
      </div>
      <div className="footer-bottom">
      </div>
      <h3 className="footer-copyright">
          &copy; {currentYear} Olecheck. {t('all_rights_reserved')}
        </h3>
    </footer>
  );
};

export default Footer;
