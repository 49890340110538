import React from 'react';
import './PresentationTab.css';
import { useTranslation } from 'react-i18next';

const PresentationTab = () => {
  const youtubeVideoUrl = "https://www.youtube.com/embed/C2RFLTbOdbs";
  const { t } = useTranslation();

  return (
    <div className="presentation-tab">
      <h2>{t('olecheckTitle')}</h2>
      <p>
        {t('olecheckDescription')}
      </p>

      <iframe
        width="634"
        height="365"
        src={youtubeVideoUrl}
        title={t('youtubePlayerTitle')}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <h3>{t('hardwareRequirements')}</h3>
      <div className="requirements">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>{t('minimum')}</th>
              <th>{t('recommended')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>{t('operatingSystem')}</th>
              <td>Windows 7 64-bit</td>
              <td>Windows 10 64-bit</td>
            </tr>
            <tr>
              <th>{t('processor')}</th>
              <td>Intel Core i5-4460</td>
              <td>Intel Core i7-3770</td>
            </tr>
            <tr>
              <th>{t('ram')}</th>
              <td>8 GB</td>
              <td>16 GB</td>
            </tr>
            <tr>
              <th>{t('graphicsCard')}</th>
              <td>NVIDIA GeForce GTX 760</td>
              <td>NVIDIA GeForce GTX 1060 6GB</td>
            </tr>
            <tr>
              <th>{t('diskSpace')}</th>
              <td>2 GB</td>
              <td>2 GB</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PresentationTab;
