import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import { usePagination, useTable } from 'react-table';
import { db } from '../middlewares/firebase';
import { addDoc, collection, getDocs, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import Message from '../components/ControlPanel/Message';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

import './ControlPanel.css';
import '../components/quill.snow.css';

const ControlPanel = () => {
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [data, setData] = useState([]);
    const [message, setMessage] = useState({ text: '', type: '' });
    const [refreshData, setRefreshData] = useState(false);
    const [selectedChangelog, setSelectedChangelog] = useState(null);
    const navigate = useNavigate();

    const columns = React.useMemo(
        () => [
            {
                Header: 'Changelogs',
                columns: [
                    {
                        Header: 'No',
                        accessor: (row, index) => index + 1,
                    },
                    {
                        Header: 'Title',
                        accessor: 'title',
                    },
                    {
                        Header: 'Date',
                        accessor: 'date',
                        Cell: ({ value }) => {
                            const date = new Date(value);
                            return (
                                <div>
                                    <div>{date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })}</div>
                                    <div>{date.toLocaleDateString()}</div>
                                </div>
                            );
                        },
                    },
                    {
                        Header: 'Actions',
                        id: 'actions',
                        Cell: ({ row }) => (
                            <div className="button-row">
                                <button className="row-button" onClick={() => handleView(row.original)}>View</button>
                                <button className="row-button" onClick={() => handleEdit(row.original)}>Edit</button>
                                <button className="row-button" onClick={() => handleDelete(row.original.id)}>Delete</button>
                                {row.original.published ? (
                                    <button className="row-button" onClick={() => handleUnpublish(row.original.id)}>Unpublish</button>
                                ) : (
                                    <button className="row-button" onClick={() => handlePublish(row.original.id)}>Publish</button>
                                )}
                            </div>
                        ),
                    },
                ],
            },
        ],
        []
    );

    const modules = {
        toolbar: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'script': 'sub' }, { 'script': 'super' }],
          [{ 'indent': '-1' }, { 'indent': '+1' }],
          [{ 'direction': 'rtl' }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          [{ 'header': 1 }, { 'header': 2 }],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'font': [] }],
          [{ 'align': [] }],
          ['link', 'image', 'video'],
          ['clean']
        ],
        clipboard: {
          matchVisual: false
        }
      };
    
      const formats = [
        'header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
        'list', 'bullet', 'script', 'indent', 'direction', 'size', 'font', 'align',
        'color', 'background', 'link', 'image', 'video'
      ];

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        prepareRow,
        state: { pageIndex },
    } = useTable({ columns, data, initialState: { pageSize: 5 } }, usePagination);


    const handleUpdate = async () => {
        if (selectedChangelog) {
            try {
                const changelogRef = doc(db, "changelogs", selectedChangelog.id);

                await updateDoc(changelogRef, {
                    title: title,
                    content: text,
                });

                setMessage({ text: `${title} updated successfully.`, type: 'success' });
                setTitle('');
                setText('');
                setSelectedChangelog(null);

                setRefreshData(true);

            } catch (error) {
                setMessage({ text: `An error occurred while updating ${title}.`, type: 'error' });
            }
        }
    };

    useTable(
        { columns, data, initialState: { pageSize: 5 } },
        usePagination
    );

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, user => {
            if (!user) {
                navigate('/');
            } else {
                fetchData();
            }
        });

        return () => unsubscribe();
    }, [navigate, refreshData]);

    const fetchData = async () => {
        const changelogsRef = collection(db, 'changelogs');
        const snapshot = await getDocs(changelogsRef);
        const changelogs = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setData(changelogs);
        setRefreshData(false);
    };

    const handleAdd = async () => {
        try {
            const newChangelog = {
                title: title,
                content: text,
                date: new Date().toISOString(),
                published: false,
            };

            const changelogsRef = collection(db, "changelogs");

            await addDoc(changelogsRef, newChangelog);

            setMessage({ text: `${title} added successfully.`, type: 'success' });
            setTitle('');
            setText('');

            setRefreshData(true);

        } catch (error) {
            setMessage({ text: `An error occurred while adding ${title}.`, type: 'error' });
        }
    };

    const handlePublish = async (id) => {
        const changelogRef = doc(db, "changelogs", id);

        await updateDoc(changelogRef, {
            published: true,
        });

        setRefreshData(true);

        setMessage({ text: `Changelog published successfully.`, type: 'success' });
    };

    const handleUnpublish = async (id) => {
        const changelogRef = doc(db, "changelogs", id);

        await updateDoc(changelogRef, {
            published: false,
        });

        setRefreshData(true);

        setMessage({ text: `Changelog unpublished successfully.`, type: 'success' });
    };

    const handleView = (changelog) => {
        // TODO: navigate to view page for changelog
    };

    const handleEdit = (changelog) => {
        setTitle(changelog.title);
        setText(changelog.content);
        setSelectedChangelog(changelog);
    };

    const handleDelete = async (id) => {
        const changelogRef = doc(db, "changelogs", id);

        await deleteDoc(changelogRef);

        setRefreshData(true);

        setMessage({ text: `Changelog deleted successfully.`, type: 'success' });
    };

    return (
        <div className="control-panel-container">
            <div className="control-panel">
                {message.text && (
                    <Message
                        text={message.text}
                        type={message.type}
                        onClose={() => setMessage({ text: '', type: '' })}
                    />
                )}
                <div className="control-panel-input-container">
                    <input
                        type="text"
                        placeholder="Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    {selectedChangelog ? (
                        <button onClick={handleUpdate} className="control-panel-button">Update</button>
                    ) : (
                        <button onClick={handleAdd} className="control-panel-button">Add New</button>
                    )}
                </div>
                <ReactQuill
                    theme="snow"
                    className="control-panel-reactQuill"
                    value={text}
                    onChange={setText}
                    modules={modules}
                    formats={formats}
                />
                <div>
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className="control-panel-pagination">
                        <button className="control-panel-paginator-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                            {'<'}
                        </button>
                        {' '}
                        <span className="control-panel-paginator-text">
                            Page{' '}
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>{' '}
                        </span>
                        <button className="control-panel-paginator-button" onClick={() => nextPage()} disabled={!canNextPage}>
                            {'>'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ControlPanel;
