import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCzqwzxkHsbL02GO2QPstSG6oimWaLwUwI",
  authDomain: "olecheck-8ed32.firebaseapp.com",
  projectId: "olecheck-8ed32",
  storageBucket: "olecheck-8ed32.appspot.com",
  messagingSenderId: "840197080283",
  appId: "1:840197080283:web:02b1f435c2a196a184b8fe"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };
