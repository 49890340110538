import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './Changelog.css'

const Changelog = ({ changelog }) => {
  const { title, date, content } = changelog;
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef();
  const [shouldShowButton, setShouldShowButton] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (contentRef.current.getBoundingClientRect().height > 322) {
      setShouldShowButton(true);
    } else {
      setShouldShowButton(false);
    }
  }, [content]);

  const formatDate = (dateStr) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    const date = new Date(dateStr);
    return date.toLocaleDateString('pl-PL', options);
  }

  return (
    <div className={`changelog ${isOpen ? 'changelog-open' : ''}`}>
      <h3 className="changelog-title">{title}</h3>
      <hr className="divider" />
      <div className="changelog-header">
        <p className="changelog-author">{`${t('added_by')} NaomiX`}</p>
        <p className="changelog-date">{`${t('posted_on')} ${formatDate(date)}`}</p>
      </div>
      <hr className="divider" />
      <div className="changelog-content" dangerouslySetInnerHTML={{ __html: content }} ref={contentRef} />
      {shouldShowButton &&
        <button className="changelog-button" onClick={() => setIsOpen(!isOpen)}>{isOpen ? t('collapse') : t('read_more')}</button>}
    </div>
  );
};

export default Changelog
