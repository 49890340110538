import HeroSection from '../components/HeroSection';
import ColumnSection from '../components/ColumnSection';

//import './App.css';

const HomePage = () => {
  return (
    <div className="App">
        <HeroSection />
        <ColumnSection />
    </div>
  );
}

export default HomePage;
