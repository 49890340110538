import React, { useState, useEffect } from 'react';
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { useTranslation } from 'react-i18next';
import { ReactComponent as PolandFlag } from '../assets/pl-flag.svg';
import { ReactComponent as UKFlag } from '../assets/uk-flag.svg';
import { ReactComponent as UkraineFlag } from '../assets/ukraine-flag.svg';
import { ReactComponent as LoginIcon } from '../assets/login.svg';
import { ReactComponent as LogoutIcon } from '../assets/logout.svg';
import { ReactComponent as ControlPanelIcon } from '../assets/control-panel.svg';
import LoginPopup from './Popups/LoginPopup';
import { useNavigate, useLocation } from 'react-router-dom';

import './TopBar.css';

const TopBar = () => {
  const { i18n } = useTranslation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleLogin = () => {
    setIsLoggedIn(true);
    navigate('/controlpanel');
  }

  const handleLogout = async () => {
    const auth = getAuth();

    try {
      await signOut(auth);
      setIsLoggedIn(false);
      navigate('/'); 
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  const handleOpenPopup = () => {
    setPopupVisible(true);
  }

  const handleClosePopup = () => {
    setPopupVisible(false);
  }

  const handleControlPanelClick = () => {
    if (location.pathname === '/controlpanel') {
      navigate('/');
    } else {
      navigate('/controlpanel');
    }
  }

  return (
    <div className="top-bar">
      <div className="flags">
        <PolandFlag className="flag" onClick={() => changeLanguage('pl')} />
        <UKFlag className="flag" onClick={() => changeLanguage('en')} />
        <UkraineFlag className="flag" onClick={() => changeLanguage('uk')} />
      </div>
      <div className="login">
        {isLoggedIn && <ControlPanelIcon className="top-bar-icon" onClick={handleControlPanelClick}/>}
        {isLoggedIn
          ? <LogoutIcon className="top-bar-icon" onClick={handleLogout} />
          : <LoginIcon className="top-bar-icon" onClick={handleOpenPopup} />
        }
        {isPopupVisible && <LoginPopup onLogin={handleLogin} onClose={handleClosePopup} />}
      </div>
    </div>
  );
};


export default TopBar;