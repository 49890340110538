import React from 'react';
import LeftColumn from './Columns/LeftColumn';
import MiddleColumn from './Columns/MiddleColumn';
import RightColumn from './Columns/RightColumn';
import './ColumnSection.css';

const ColumnSection = () => (
  <div className="columns-section">
    <LeftColumn />
    <MiddleColumn />
    <RightColumn />
  </div>
);

export default ColumnSection;