import React, { useEffect, useState } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../middlewares/firebase';
import { useTranslation } from 'react-i18next';
import headerImage from '../../assets/img3.png';
import Coffee from '../Coffee';

import './LeftColumn.css';

const LeftColumn = () => {
  const { t } = useTranslation();

  const [downloadsCount, setDownloadsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [counterAnimation, setCounterAnimation] = useState(false);

  useEffect(() => {
    const countRef = doc(db, 'downloads', 'Flh51nN8HYYqPA29wygq');
    const unsubscribe = onSnapshot(countRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        const count = data.count;
        setDownloadsCount(count);
        setIsLoading(false); // Zakończ ładowanie danych
        setCounterAnimation(true); // Włącz animację po zmianie wartości
        setTimeout(() => {
          setCounterAnimation(false); // Wyłącz animację po pewnym czasie
        }, 1000);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="left-column">
      <div className="left-column-header-container">
        <div className="image-container">
          <img src={headerImage} alt="Header" className="left-column-header-image" />
          <div className="left-column-header-text">
            <h2 className="left-column-downloads-heading">{t('downloads')}</h2>
            {isLoading ? (
              <p className="left-column-counter loading-animation"></p>
            ) : (
              <p className={`left-column-counter ${counterAnimation ? 'counter-animation' : ''}`}>
                {downloadsCount}
              </p>
            )}
          </div>
        </div>
      </div>
      <Coffee className="left-column-buy-me-a-coffee" />
    </div>
  );
};

export default LeftColumn;
