import React from 'react';
import { useTranslation } from 'react-i18next';
import { db, storage } from './../middlewares/firebase';
import { doc, updateDoc, increment, getDoc } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import headerImage from '../assets/img1.png';
import headerLogo from '../assets/img2.png';
import buttonFrame from '../assets/btn1.png';

import './HeroSection.css';

const HeroSection = () => {
  const { t } = useTranslation();

  const handleDownload = async () => {
    try {
      const docRef = doc(db, "downloads", "Flh51nN8HYYqPA29wygq");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const file_name = docSnap.data().file_name;
  
        const storageRef = ref(storage, `releases/${file_name}`);
        const url = await getDownloadURL(storageRef);
  
        const link = document.createElement('a');
        link.href = url;
        link.download = file_name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        const countRef = doc(db, "downloads", "Flh51nN8HYYqPA29wygq");
        await updateDoc(countRef, {
          count: increment(1)
        });
      } else {
        console.error("Dokument 'downloads' nie istnieje.");
      }
    } catch (error) {
      console.error('Błąd podczas pobierania pliku:', error);
    }
  };
  
  
  return (
    <div className="hero-section">
      <img src={headerImage} alt="background" className="background-image"/>
      <img src={headerLogo} alt="header logo" className="headerLogo-image"/>
      <button 
        className="download-button" 
        style={{ backgroundImage: `url(${buttonFrame})` }}
        onClick={handleDownload}
      >
        <p className='download-text'>{t('download_game_now')}</p>
      </button>
    </div>
  );
};

export default HeroSection;
