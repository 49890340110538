import React, { useEffect, useState } from 'react';
import './Message.css';

const Message = ({ text, type, onClose }) => {
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowMessage(false);
      onClose();
    }, 3000); // 3000 ms = 3 sekundy

    return () => {
      clearTimeout(timeout);
    };
  }, [onClose]);

  return (
    <>
      {showMessage && (
        <div className={`message ${type}`}>
          <p>{text}</p>
        </div>
      )}
    </>
  );
};

export default Message;
