import { Routes, Route } from 'react-router-dom';
import Footer from './components/Footer';
import TopBar from './components/TopBar';
import ControlPanel from './pages/ControlPanel';
import HomePage from './pages/HomePage';

import './App.css';

function App() {
  return (
    <div className="App">
      <TopBar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/controlpanel" element={<ControlPanel />} />
      </Routes>
      <Footer />
    </div>
  );
}
export default App;
