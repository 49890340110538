import { useEffect, useState, useRef } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';

import ChangelogTab from './MiddleColumn/ChangelogTab';
import PresentationTab from './MiddleColumn/PresentationTab';

import './/MiddleColumn.css';

const MiddleColumn = () => {
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState('changelog');
  const { t } = useTranslation();
  const changelogRef = useRef(null);
  const presentationRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const firestore = getFirestore();
        const changelogsRef = collection(firestore, 'changelogs');
        const snapshot = await getDocs(changelogsRef);
        const changelogs = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        changelogs.sort((a, b) => new Date(b.date) - new Date(a.date));
        setData(changelogs);
      } catch (error) {
        console.error('Error fetching changelogs:', error);
      }
    };

    fetchData();
  }, [activeTab]);

  return (
    <div className="middle-column">
      <div className="tabs">
        <button
          ref={changelogRef}
          className={activeTab === 'changelog' ? 'active' : ''}
          onClick={() => setActiveTab('changelog')}
        >
          {t('changelog')}
        </button>
        <button
          ref={presentationRef}
          className={activeTab === 'presentation' ? 'active' : ''}
          onClick={() => setActiveTab('presentation')}
        >
          {t('presentation')}
        </button>

      </div>

      <div className={`tab-content ${activeTab === 'changelog' ? 'active' : ''}`}>
        <ChangelogTab data={data} />
      </div>
      <div className={`tab-content ${activeTab === 'presentation' ? 'active' : ''}`}>
        <PresentationTab />
      </div>
    </div>
  );
};

export default MiddleColumn;