import React from 'react';
import Changelog from './Changelog';

import './ChangelogTab.css';

const ChangelogTab = ({ data }) => {
  return (
    <div class="changelog-tab">
      {data.map(changelog => (
        <Changelog key={changelog.id} changelog={changelog} />
      ))}
    </div>
  );
};

export default ChangelogTab;
