import React from 'react';
import './RightColumn.css';

const RightColumn = () => (
  <div className="right-column">
    <p>Right</p>
    <p>Last releases</p>
  </div>
);

export default RightColumn;
